/* General Container Styles for Better Mobile Experience */
.App {
  text-align: center;
  padding: 15px;
}

/* Responsive Header Container */
.header-container {
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
  padding: 15px;
  max-width: 100%;
  margin: auto;
}

.logo-img {
  width: 200px;  /* Increased size for better visibility on both desktop and mobile */
  height: auto;
  margin-right: 15px;
}

/* Increase the size for mobile devices */
@media (max-width: 768px) {
  .logo-img {
    width: 220px;  /* Larger size specifically for mobile */
  }
}

/* Responsive H1 Header */
.responsive-header {
  font-size: 2rem; /* Increased font size for desktop and mobile */
  text-align: center;
}

/* Adjust the Filter Input for Mobile */
.filter-input {
  width: 100%;
  max-width: 500px; /* Adjusted width for better fit on all devices */
  margin: 10px auto;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

/* Horizontal Scrollbar Styles for Table Wrapper */
.table-scroll-wrapper {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
  margin-bottom: 10px; /* Space between scrollbar and table */
}

.table-scroll-wrapper::-webkit-scrollbar {
  height: 8px; /* Height of scrollbar */
}

.table-scroll-wrapper::-webkit-scrollbar-thumb {
  background: #a3a3a3; /* Gray color for scrollbar thumb */
  border-radius: 5px; /* Rounded corners for a more polished look */
}

.table-scroll-wrapper::-webkit-scrollbar-track {
  background: #f1f1f1; /* Background of scrollbar track */
}

/* Table Adjustments for Smaller Screens */
.table {
  width: 100%; /* Make sure the table takes full width */
  font-size: 1rem; /* Adjusted font size for readability */
}

.table td,
.table th {
  padding: 0.75rem; /* Added more padding for better spacing */
  word-wrap: break-word; /* Prevent content overflow */
  vertical-align: middle;
}

/* Sorting Button Styling Adjustments for Mobile */
.sort-buttons {
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
  margin-top: 10px; /* Increase the spacing between the title and the buttons */
}

.reset-button {
  padding: 5px 10px; /* Adjust button size for better fit */
  font-size: 0.85rem;
}

/* Adjust Header and Logo Wrapping */
.header-container img,
.header-container h1 {
  margin: auto; /* Center each element */
}

/* Smooth scrolling for better experience */
html {
  scroll-behavior: smooth;
}

/* Ensure table header buttons have adequate spacing */
@media (max-width: 768px) {
  .sort-buttons {
    flex-direction: row;
    gap: 3px; /* Add minimal spacing between buttons */
  }

  .reset-button {
    margin-top: 5px; /* Add spacing above reset button */
  }

  /* Reduce padding for mobile view */
  .header-container {
    padding: 1rem;
  }

  /* Smaller text for mobile */
  th, td {
    font-size: 0.85rem;
  }
}

@keyframes blurInOut {
  0% {
    opacity: 0;
    filter: blur(10px);
  }
  50% {
    opacity: 1;
    filter: blur(0px);
  }
  100% {
    opacity: 0;
    filter: blur(10px);
  }
}

.loading-logo {
  width: 200px;  /* Adjust size as needed */
  animation: blurInOut 2s ease-in-out;
  margin: 20% auto; /* Centers the logo vertically and horizontally */
}
